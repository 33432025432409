import { Stocked_Vehicles } from '../types/graphql';
import { Showroom } from './showrooms/getShowroomDomainServerSide';

type Vehicle = Pick<Stocked_Vehicles, 'make' | 'production_year' | 'condition'>;

/**
 * This is a temporary service to get the Polestar Novated Lease data
 *
 * We'll replace this with a holistic approach for managing payment methods on a car by car basis
 */
export const tempPolestarNovatedLease = (showroomCode: string, vehicle: Vehicle) => {
  if (!vehicle) {
    return false;
  }

  return (
    ['vyro', 'actewagl', 'astrazeneca'].includes(showroomCode) &&
    vehicle.make === 'Polestar' &&
    vehicle.production_year !== 2023 &&
    vehicle.condition === 'new'
  );
};
