import { truncate } from 'lodash';
import { n } from '../../../../../services/formatNumber';
import { getConditionName } from '../../../../../services/getConditionName';
import { Vehicle_Conditions_Enum } from '../../../../../types/graphql';
import { Props as VehicleCardProps } from '../../../types';
import styles from './index.module.scss';

type Props = {
  vehicle: VehicleCardProps['vehicle'];
};

export const TopFeatures = (props: Props) => {
  const { vehicle } = props;

  return (
    <ul className={styles.topFeatures}>
      {[
        getConditionName(vehicle.condition),
        vehicle.condition !== Vehicle_Conditions_Enum.New && `${n(vehicle.odometer)} km`,
        vehicle.colour,
      ]
        .filter(Boolean)
        .map((item, index) => (
          <li key={index}>{truncate(item, { length: 25 })}</li>
        ))}
    </ul>
  );
};
