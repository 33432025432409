import classnames from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import ImageGallery from 'react-image-gallery';
import Button from '../Button';
import LoadingIcon from '../LoadingIcon';
import styles from './index.module.scss';

export type GalleryItemObject = {
  original: string;
  fullscreen?: string;
  thumbnail?: string;
  label?: React.ReactNode;
  bulletClass?: string;
};

export type Props = {
  items?: Array<GalleryItemObject>;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  height?: number;
  showNav?: boolean;
  showBullets?: boolean;
  helpText?: React.ReactNode;
  className?: string;
  allowFullscreen?: boolean;
  showNavigation?: boolean;
  showLabel?: boolean;
  showItemNumber?: boolean;
} & Record<any, any>;

export default forwardRef(function Gallery(props: Props, ref) {
  const {
    items,
    className,
    header,
    showNav = true,
    footer,
    height,
    helpText,
    isRounded = true,
    allowFullscreen = true,
    showNavigation = true,
    showBullets = false,
    showIndex = false,
    showLabel = false,
    showItemNumber = true,
    ...otherProps
  } = props;

  const [isFullScreen, setIsFullScreen] = useState(false);

  const galleryRef = useRef();
  useImperativeHandle(ref, () => galleryRef.current);

  const openFullscreen = () => {
    if (galleryRef.current) {
      (galleryRef.current as any).toggleFullScreen();
    }
  };

  const heightStyle = {
    height: height ? `${height}px` : undefined,
  };

  const [touchStartTime, setTouchStartTime] = useState<Date>(null);
  const [touchDidMove, setTouchDidMove] = useState(false);
  const onTouchEnd = () => {
    const touchDuration = new Date().getMilliseconds() - touchStartTime.getMilliseconds();
    if (touchDuration < 200 && !touchDidMove && allowFullscreen) {
      openFullscreen();
    }
  };

  const [index, setIndex] = useState(0);

  const currentItem = items && items[index];

  return (
    <div
      className={classnames(
        styles.gallery,
        showNav && styles.showNav,
        isFullScreen && styles.isFullScreen,
        isRounded && styles.isRounded,
        className,
      )}
    >
      <div className={styles.galleryWrapper} style={heightStyle}>
        <ImageGallery
          ref={galleryRef}
          onTouchStart={() => {
            setTouchDidMove(false);
            setTouchStartTime(new Date());
          }}
          onTouchMove={() => setTouchDidMove(true)}
          onTouchEnd={() => onTouchEnd()}
          items={(items || []).map((item, i) => ({ ...item, i, bulletClass: styles.bullets }))}
          lazyLoad
          startIndex={index}
          onSlide={setIndex}
          showIndex={showIndex && (items || []).length > 1}
          showThumbnails={false}
          showPlayButton={false}
          showBullets={showBullets}
          onScreenChange={setIsFullScreen}
          renderItem={(item) => (
            <GalleryItem
              item={item}
              heightStyle={heightStyle}
              isFullScreen={isFullScreen}
              thumbnailLabel={item.label}
            />
          )}
          renderLeftNav={() =>
            items.length > 1 &&
            showNavigation && (
              <Button
                size="small"
                className={classnames(styles.control, styles.prev)}
                onClick={(e) => {
                  e.preventDefault();
                  galleryRef.current && (galleryRef.current as any).slideLeft();
                }}
                aria-label="Previous Slide"
              >
                <AiOutlineArrowLeft />
              </Button>
            )
          }
          renderRightNav={() =>
            items.length > 1 &&
            showNavigation && (
              <Button
                size="small"
                className={classnames(styles.control, styles.next)}
                onClick={(e) => {
                  e.preventDefault();
                  galleryRef.current && (galleryRef.current as any).slideRight();
                }}
              >
                <AiOutlineArrowRight />
              </Button>
            )
          }
          renderFullscreenButton={(onClick, isFullScreen) =>
            isFullScreen ? (
              <Button
                size="small"
                color="grey"
                className={styles.exitFullscreenButton}
                onClick={() => galleryRef.current && allowFullscreen && (galleryRef.current as any).toggleFullScreen()}
                aria-label="Fullscreen"
              >
                <AiOutlineFullscreenExit />
              </Button>
            ) : null
          }
          {...otherProps}
        />
        {allowFullscreen && (
          <button className={classnames(styles.control, styles.fullscreen)} onClick={() => openFullscreen()}>
            <AiOutlineFullscreen />
          </button>
        )}
      </div>

      <div className={styles.loadingBar} />

      {showLabel && (
        <div className={styles.label}>
          {showItemNumber && (
            <span className={styles.index}>
              {index + 1} / {items.length}
            </span>
          )}

          {currentItem?.label}
        </div>
      )}

      {!!helpText && <div className={styles.help}>{helpText}</div>}
    </div>
  );
});

type GalleryItemProps = {
  item: GalleryItemObject;
  heightStyle: { height: string };
  isFullScreen: boolean;
  thumbnailLabel?: string;
};

function GalleryItem(props: GalleryItemProps) {
  const [loading, setLoading] = useState(true);
  const imgRef = useRef<HTMLImageElement>(null);

  const imageLoaded = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (imgRef.current?.complete) {
      imageLoaded();
    }
  }, []);

  return (
    <div className={classnames(styles.galleryItem, props.isFullScreen && styles.isFullScreen)}>
      <div
        style={{
          visibility: loading ? 'visible' : 'collapse',
        }}
        className={styles.galleryLoading}
      >
        <LoadingIcon size={24} />
      </div>
      <img
        ref={imgRef}
        src={props.isFullScreen ? props.item.fullscreen : props.item.original}
        onLoad={imageLoaded}
        style={props.heightStyle}
        // @ts-ignore
        // Using fetchPriority to prioritise the first (and visible) image
        // See: https://web.dev/priority-hints/
        fetchPriority={props.item.i === 0 ? 'high' : 'low'}
      />
      <div className={classnames(styles.thumbnailLabel)}>{props.isFullScreen ? props.thumbnailLabel : ''}</div>
    </div>
  );
}
