import { Media } from '@vyro-x/vehicle-order-types';
import classnames from 'classnames';
import { truncate } from 'lodash';
import Link from 'next/link';
import { useRef } from 'react';
import { getImagekitUrl } from '../../../../services/getImagekitUrl';
import { getVehicleTitle } from '../../../../services/getVehicleTitle';
import { useAspectRatio } from '../../../../services/useAspectRatio';
import Gallery from '../../../Gallery';
import { Props } from '../../types';
import { Footer } from './Footer';
import styles from './index.module.scss';
import { Pricing } from './Pricing';
import { TempPolestarNovatedLeasing } from './TempPolestarNovatedLeasing';
import { TopFeatures } from './TopFeatures';
import { Wishlist } from './Wishlist';
import { Vehicle_Conditions_Enum } from '../../../../types/graphql';

export default function StandardVehicleCard(props: Props) {
  const {
    className,
    vehicle,
    href = `/vehicles/stocked/${vehicle.code}`,
    onAddToWishlist,
    isOnWishlist,
    showAvailability = true,
    mediaFit = 'crop',
    wishlistVariant = 'heart-icon',
    onClick,
    finance,
    variant,
    ...otherProps
  } = props;

  const media: Array<Media> = vehicle.media || [];

  const galleryRef = useRef<HTMLDivElement>(null);
  const height = useAspectRatio(galleryRef, [16, 9]);
  const galleryItems = [
    media.find((item) => item.placement === 'featured'),
    ...media.filter((item) => item.placement === 'gallery'),
  ]
    .filter(Boolean)
    .map((item) => {
      return {
        original: getImagekitUrl(item.src, { w: 850, h: 480, fit: 'crop', 'c-at_min': true }),
        fullscreen: item.src,
        label: truncate(item.label || item.alt, { length: 50 }),
      };
    });

  return (
    <Link href={href}>
      <a onClick={onClick as any} className={classnames(styles.vehicleCard, className)} {...otherProps}>
        <div ref={galleryRef}>
          <Gallery
            items={galleryItems}
            isRounded={false}
            showNavigation
            height={height}
            allowFullscreen={false}
            className={styles.gallery}
          />
        </div>

        <div className={styles.body}>
          <div className={styles.titleAndWishlist}>
            <h3 className={styles.title}>
              {[
                [Vehicle_Conditions_Enum.Used, Vehicle_Conditions_Enum.Demo].includes(vehicle.condition) &&
                  vehicle.production_year,
                vehicle.make,
                vehicle.model,
                vehicle.badge,
              ]
                .filter(Boolean)
                .join(' ')}
            </h3>

            {typeof onAddToWishlist === 'function' && (
              <Wishlist onAddToWishlist={onAddToWishlist} isOnWishlist={isOnWishlist} />
            )}
          </div>

          <TopFeatures vehicle={vehicle} />

          <Pricing vehicle={vehicle} finance={finance} />

          <TempPolestarNovatedLeasing vehicle={vehicle} />
        </div>

        <Footer vehicle={vehicle} showAvailability={showAvailability} />
      </a>
    </Link>
  );
}
