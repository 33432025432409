import { Props as VehicleCardProps } from '../../../types';
import { Availability } from '../Availability';
import styles from './index.module.scss';

type Props = {
  vehicle: VehicleCardProps['vehicle'];
  showAvailability: boolean;
};

export const Footer = (props: Props) => {
  const { vehicle } = props;

  return (
    <div className={styles.footer}>
      <Availability vehicle={vehicle} />
    </div>
  );
};
