import dayjs from 'dayjs';
import { AVAILABLE_NOW_THRESHOLD_DAYS } from '../constants';

export const getVehicleAvailabilityInDays = (availableFrom: string, estimatedDeliveryDelay: number) => {
  const today = dayjs();
  let date = dayjs(availableFrom);

  // If the availableFrom date is in the past, use the estimatedDeliveryDelay
  if (date.isBefore(today)) {
    date = today.add(estimatedDeliveryDelay, 'day');
  }

  return date.diff(today, 'days');
};

export const describeVehicleAvailability = (availableFrom: string, estimatedDeliveryDelay: number): string => {
  const date = dayjs(availableFrom);
  const daysToGo = getVehicleAvailabilityInDays(availableFrom, estimatedDeliveryDelay);

  if (daysToGo <= AVAILABLE_NOW_THRESHOLD_DAYS) {
    return 'Now';
  }

  let month = date.format('MMMM');
  if (month.length > 6) {
    month = date.format('MMM');
  }

  if (daysToGo < 90) {
    const day = date.get('date');
    let rel;
    if (day < 10) rel = 'Early';
    if (day < 20) rel = 'Mid';
    rel = 'Late';

    return `${rel} ${month}`;
  }

  return month;
};
