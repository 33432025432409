import { Media, PricingConfigV2 } from '@vyro-x/vehicle-order-types';
import classnames from 'classnames';
import { sumBy, truncate } from 'lodash';
import Link from 'next/link';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AVAILABLE_NOW_THRESHOLD_DAYS } from '../../../../constants';
import { d, useCurrency } from '../../../../services/formatCurrency';
import { n } from '../../../../services/formatNumber';
import { getConditionName } from '../../../../services/getConditionName';
import { getImagekitUrl } from '../../../../services/getImagekitUrl';
import { getImgxUrl } from '../../../../services/getImgxUrl';
import { describeVehicleAvailability, getVehicleAvailabilityInDays } from '../../../../services/vehicleAvailability';
import Gallery from '../../../Gallery';
import { Props } from '../../types';
import styles from './index.module.scss';
import { useRef, useState } from 'react';
import Tag from '../../../Tag';
import { WishlistHeartIcon } from '../../../WishlistHeartIcon';
import CircularProgress from '../../../CircularProgress';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { getImageCdnUrl } from '../../../../services/getImageCdnUrl';

export default function PolestarVehicleCard(props: Props) {
  const {
    className,
    vehicle,
    rate,
    href = `/vehicles/stocked/${vehicle.code}`,
    showAvailability = true,
    mediaFit = 'crop',
    onClick,
    ctaLabel,
    isOnWishlist,
    onAddToWishlist,
    galleryShowLabel = false,
    finance,
    ...otherProps
  } = props;

  const { d } = useCurrency();

  const [eagerIsOnWishList, setEagerIsOnWishList] = useState(isOnWishlist);
  const _isOnWishlist = isOnWishlist || eagerIsOnWishList;

  const availability = (() => {
    if (!showAvailability || !vehicle.available_from || typeof vehicle.estimated_delivery_delay !== 'number') {
      return null;
    }

    const daysToGo = getVehicleAvailabilityInDays(vehicle.available_from, vehicle.estimated_delivery_delay);

    const timeFrame = describeVehicleAvailability(vehicle.available_from, vehicle.estimated_delivery_delay);

    return {
      element: timeFrame,
      isNow: daysToGo <= AVAILABLE_NOW_THRESHOLD_DAYS,
    };
  })();

  const media: Array<Media> = vehicle.media || [];
  const galleryItems = [
    media.find((item) => item.placement === 'featured'),
    ...media.filter((item) => item.placement === 'gallery'),
  ]
    .filter(Boolean)
    .map((item) => {
      const dimensions = { w: 850, h: 480 };
      return {
        original: getImageCdnUrl(item.src, {
          ...dimensions,
          'c-at_min': true,
        }),
        fullscreen: item.src,
        label: truncate(item.label || item.alt, { length: 50 }),
      };
    });

  const galleryRef = useRef(null);
  const width = (galleryRef.current as HTMLDivElement)?.getBoundingClientRect().width;
  const height = width * 0.564;

  const priceBeforeOnRoads = sumBy((vehicle.pricing_config as PricingConfigV2).lineItems.dutiable, 'value');

  const showMonthlyRepayment = finance?.repayment > 0;

  const showroomTheme = useShowroomSettings(['colors']);

  return (
    <div
      ref={galleryRef}
      className={classnames(styles.vehicleCard, otherProps?.isStandout && styles.standout, className)}
      {...otherProps}
    >
      <Gallery
        showLabel={galleryShowLabel}
        items={galleryItems}
        isRounded={false}
        showNavigation
        height={height}
        allowFullscreen={false}
        className={styles.gallery}
      />

      <div className={styles.yearModel}>
        <span>
          <span>
            {vehicle.make} {vehicle.model} | {vehicle.production_year}
          </span>
          {vehicle.is_sold && (
            <span className={styles.sold}>
              <Tag color="black">SOLD</Tag>
            </span>
          )}
        </span>
        <WishlistHeartIcon
          onAddToWishlist={onAddToWishlist}
          className={styles.wishlist}
          isOnWishlist={_isOnWishlist}
          setEagerIsOnWishList={setEagerIsOnWishList}
        />
        {otherProps?.similarity && (
          <CircularProgress
            percentage={otherProps.similarity * 100}
            colour={showroomTheme?.colors['primary']}
            className={styles.circularProgress}
          />
        )}
      </div>

      <div className={styles.badge}>{vehicle.badge}</div>

      <div className={styles.specs}>
        {[
          availability && {
            label: 'Estimated Delivery',
            value: vehicle.is_sold ? (
              '(Sold)'
            ) : (
              <span className={availability.isNow && styles.availabilityIsNow}>
                {availability.isNow ? 'Available now' : availability.element}
              </span>
            ),
          },
          vehicle.condition && { label: 'Condition', value: getConditionName(vehicle.condition) },
          vehicle.efficiency && { label: 'Avg. consumption', value: `${n(vehicle.efficiency)} Wh/km` },
          vehicle.motor_size && { label: 'Power', value: `${n(vehicle.motor_size)} kWh` },
          vehicle.range && { label: 'Range (WLTP)', value: `${n(vehicle.range)} km` },
          vehicle.acceleration_0_100_km && { label: '0 - 100 km/h', value: `${n(vehicle.acceleration_0_100_km)} sec` },
          vehicle.colour && { label: 'Exterior', value: vehicle.colour },
          vehicle.interior && { label: 'Interior', value: vehicle.interior },
          vehicle.wheel_name && { label: 'Wheels', value: vehicle.wheel_name },
          typeof vehicle.odometer === 'number' &&
            vehicle.condition !== 'new' && { label: 'Odometer', value: `${n(vehicle.odometer)} km` },
          { label: 'Packs', value: vehicle.packs || '-' },
          {
            label: <>Price</>,
            value: <>{d(priceBeforeOnRoads)}</>,
          },
        ]
          .filter(Boolean)
          .map((spec, i) => (
            <div className={styles.spec} key={i}>
              <div className={styles.label}>{spec.label}</div>
              <div className={styles.value}>{spec.value}</div>
            </div>
          ))}
      </div>

      <div className={styles.ctaSection}>
        <Link href={href}>
          <a className={styles.cta} onClick={onClick as any}>
            <span className={styles.price}>
              {(() => {
                if (vehicle.is_sold) {
                  return (
                    <>
                      Not for sale
                      <br />
                      <small>Sold recently...</small>
                    </>
                  );
                }

                if (showMonthlyRepayment) {
                  return (
                    <>
                      <small className={styles.from}>From *</small>
                      <div className={styles.repayment}>
                        {d(finance.repayment, { precision: 0 })}{' '}
                        <small>/ {finance.repaymentFrequency === 'monthly' ? 'mo' : 'wk'}</small>
                      </div>
                      <small className={styles.settlementMethod}>{finance?.name}</small>
                    </>
                  );
                }

                return d(priceBeforeOnRoads);
              })()}
            </span>

            <span className={styles.label}>
              {ctaLabel || 'More details'}
              <AiOutlineArrowRight className={styles.arrow} />
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
}
