import classnames from 'classnames';
import { sumBy } from 'lodash';
import { useCurrency } from '../../../../../services/formatCurrency';
import { Props as VehicleCardProps } from '../../../types';
import styles from './index.module.scss';
import { useShowroomSettings } from '../../../../../services/showrooms/useShowroomSettings';
import { PricingConfigV2 } from '@vyro-x/pricing-client';
import { PricingLabel } from '../../../../../services/pricingCodes/PricingLabel';

type Props = {
  vehicle: VehicleCardProps['vehicle'];
  finance: VehicleCardProps['finance'];
};

export const Pricing = (props: Props) => {
  const { vehicle, finance } = props;

  const { d } = useCurrency();

  const pricingConfig: PricingConfigV2 = vehicle.pricing_config;
  const totalDiscount = sumBy(
    Object.values(pricingConfig.lineItems)
      .flat()
      .filter((item) => item.code === 'discount'),
    'value',
  );
  const priceBeforeDiscount = pricingConfig.driveAwayPrice + Math.abs(totalDiscount);

  const showMonthlyRepayment = finance?.repayment > 0;

  return (
    <div className={styles.pricing}>
      <div className={styles.item}>
        <div className={styles.label}>Price</div>
        {!!totalDiscount && <div className={styles.striked}>{d(priceBeforeDiscount)}</div>}
        <div className={styles.value}>
          {d(pricingConfig.driveAwayPrice)}
          <small className={styles.sub}><PricingLabel pricingConfig={pricingConfig} /></small>
        </div>
      </div>

      {showMonthlyRepayment && (
        <div className={classnames(styles.item, styles.finance)}>
          <div className={styles.label}>{finance.name} from*</div>
          <div className={styles.value}>
            {d(finance.repayment, { precision: 0 })}
            <small className={styles.sub}>/{finance.repaymentFrequency === 'monthly' ? 'mo' : 'wk'}</small>
          </div>
        </div>
      )}
    </div>
  );
};
