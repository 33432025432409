import { PricingConfigV2 } from '@vyro-x/vehicle-order-types';
import classnames from 'classnames';
import { sumBy } from 'lodash';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useCurrency } from '../../../../../services/formatCurrency';
import { useShowroom } from '../../../../../services/showrooms/useShowroom';
import { tempPolestarNovatedLease } from '../../../../../services/tempPolestarNovatedLease';
import { Props as VehicleCardProps } from '../../../types';
import styles from './index.module.scss';

type Props = {
  vehicle: VehicleCardProps['vehicle'];
};

export const TempPolestarNovatedLeasing = (props: Props) => {
  const { vehicle } = props;

  const showroom = useShowroom();

  const url: string = ['actewagl', 'astrazeneca'].includes(showroom?.code)
    ? '/novated-leasing'
    : '/novated-leasing/polestar';

  const isEnabled = tempPolestarNovatedLease(showroom.code, vehicle);

  if (!isEnabled) {
    return null;
  }

  return (
    <div className={styles.tempPolestarNovatedLeasing}>
      <AiOutlineInfoCircle />
      Novated lease only -{' '}
      <a href={url} target="_blank">
        Learn more
      </a>
    </div>
  );
};
