import { Media } from '@vyro-x/vehicle-order-types';
import classnames from 'classnames';
import { truncate } from 'lodash';
import { useRef, useState } from 'react';
import { LiaAwardSolid } from 'react-icons/lia';
import { useCurrency } from '../../../../services/formatCurrency';
import { getConditionName } from '../../../../services/getConditionName';
import { getImagekitUrl } from '../../../../services/getImagekitUrl';
import { getImgxUrl } from '../../../../services/getImgxUrl';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { useAspectRatio } from '../../../../services/useAspectRatio';
import Button from '../../../Button';
import Gallery from '../../../Gallery';
import { getFixedRateFinance } from '../../../payment-calculators/products/FixedRate/calculate';
import Tag from '../../../Tag';
import { WishlistHeartIcon } from '../../../WishlistHeartIcon';
import { Props } from '../../types';
import styles from './index.module.scss';
import { getPricingSubText } from '../../../../services/pricingCodes/PricingSubText';
import { getPricingLabel } from '../../../../services/pricingCodes/PricingLabel';

export default function CbaVehicleCard(props: Props) {
  const {
    className,
    vehicle,
    rate,
    href = `/vehicles/stocked/${vehicle.code}`,
    showAvailability = true,
    mediaFit = 'crop',
    onClick,
    ctaLabel,
    isOnWishlist,
    onAddToWishlist,
    galleryShowLabel = false,
    ...otherProps
  } = props;

  const { d } = useCurrency();

  const [eagerIsOnWishList, setEagerIsOnWishList] = useState(isOnWishlist);
  const _isOnWishlist = isOnWishlist || eagerIsOnWishList;

  const showroom = useShowroom();

  const showroomSettings = useShowroomSettings([
    'finance_locked_interest_rate',
    'finance_locked_comparison_rate',
    'vehicle_order_min_deposit',
    'finance_repayment_frequency',
    'pricing_drive_away_label',
    'pricing_excl_on_roads_label',
    'pricing_excl_on_roads_sub_text',
    'pricing_drive_away_sub_text'
  ]);

  const media: Array<Media> = vehicle.media || [];
  const galleryItems = [
    media.find((item) => item.placement === 'featured'),
    ...media.filter((item) => item.placement === 'gallery'),
  ]
    .filter(Boolean)
    .map((item) => {
      const dimensions = { w: 1600, h: 900 };
      return {
        original: getImagekitUrl(getImgxUrl(item.src, { ...dimensions, fit: 'crop' }), {
          ...dimensions,
          x: 0,
          y: 0,
          cm: 'extract',
          // 'c-at_min': true,
        }),
        fullscreen: item.src,

        label: truncate(item.label || item.alt, { length: 50 }),
      };
    });

  const galleryRef = useRef(null);
  const galleryHeight = useAspectRatio(galleryRef, [16, 9]);

  const finance = getFixedRateFinance({
    downpayment: showroomSettings.vehicle_order_min_deposit,
    vehiclePrice: vehicle.pricing_config.driveAwayPrice,
    interestRate: showroomSettings.finance_locked_interest_rate,
    comparisonRate: showroomSettings.finance_locked_comparison_rate,
    creditTerm: 84,
    balloon: 0,
    feeSchedule: [],
    repaymentFrequency: showroomSettings.finance_repayment_frequency,
  });

  const offers =
    showroom.code === 'cba' && vehicle.fuel_type === 'electric'
      ? [
        { name: '0.25% discount - Commbank personal loan' },
        { name: '$500 off of your home energy bill' },
        vehicle.make === 'Polestar' && { name: 'Free evnex EV charger worth $1,100' },
      ].filter(Boolean)
      : [];

  return (
    <div ref={galleryRef} className={classnames(styles.vehicleCard, className)} {...otherProps}>
      <Gallery
        showLabel={galleryShowLabel}
        items={galleryItems}
        isRounded={false}
        showNavigation
        height={galleryHeight}
        allowFullscreen={false}
      />

      <div className={styles.content}>
        <div className={styles.yearModel}>
          <span>
            <span>{vehicle.production_year}</span>
            {' • '}
            <span>{getConditionName(vehicle.condition)}</span>
            {vehicle.is_sold && (
              <span className={styles.sold}>
                <Tag color="black">Sold</Tag>
              </span>
            )}
          </span>
          <WishlistHeartIcon
            onAddToWishlist={onAddToWishlist}
            className={styles.wishlist}
            isOnWishlist={_isOnWishlist}
            setEagerIsOnWishList={setEagerIsOnWishList}
          />
        </div>

        <div className={styles.title}>
          <div className={styles.makeModel}>
            {vehicle.make} {vehicle.model}
          </div>
          <div className={styles.badge}>{vehicle.badge}</div>
        </div>

        {!!offers.length && (
          <div className={styles.offers}>
            {offers.map((item, i) => (
              <div className={styles.item} key={i}>
                <div className={styles.icon}>
                  <LiaAwardSolid />
                </div>

                <div className={styles.name}>{item.name}</div>
              </div>
            ))}
          </div>
        )}

        <div className={styles.pricing}>
          {[
            {
              pre: 'From',
              value: d(vehicle.pricing_config.driveAwayPrice),
              description: vehicle.pricing_config.isVariable ? getPricingSubText(vehicle.pricing_config, showroomSettings) : getPricingLabel(vehicle.pricing_config, showroomSettings),
            },
            {
              value: `${d(finance.repayment / 4.333)} / wk`,
              description: 'x.xx% interest, x.xx% comparison, x weeks',
            },
          ].map((item, i) => (
            <div className={styles.item} key={i}>
              <div className={styles.pre}>{item.pre}</div>
              <div className={styles.value}>{item.value}</div>
              <div className={styles.description}>{item.description}</div>
            </div>
          ))}
        </div>

        <div className={styles.ctaSection}>
          <Button href={href} onClick={onClick as any} isBordered color={'darkGrey'}>
            Read more
          </Button>
        </div>
      </div>
    </div>
  );
}
