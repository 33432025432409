import { Media, PricingConfigV2, PricingLineItem } from '@vyro-x/vehicle-order-types';
import classnames from 'classnames';
import { useRef, useState } from 'react';
import { AiFillHeart, AiOutlineInfoCircle } from 'react-icons/ai';
import { RiHeartAddLine } from 'react-icons/ri';
import { sumBy } from 'lodash';
import { AVAILABLE_NOW_THRESHOLD_DAYS } from '../../../../constants';
import { d } from '../../../../services/formatCurrency';
import { n } from '../../../../services/formatNumber';
import { getConditionName } from '../../../../services/getConditionName';
import { getImageCdnUrl } from '../../../../services/getImageCdnUrl';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { tempPolestarNovatedLease } from '../../../../services/tempPolestarNovatedLease';
import { useAspectRatio } from '../../../../services/useAspectRatio';
import { useGovtIncentives } from '../../../../services/useGovtIncentives';
import { describeVehicleAvailability, getVehicleAvailabilityInDays } from '../../../../services/vehicleAvailability';
import Button from '../../../Button';
import Card from '../../../Card';
import Tag from '../../../Tag';
import { WishlistHeartIcon } from '../../../WishlistHeartIcon';
import { Props } from '../../types';
import styles from './index.module.scss';

export default function LotusVehicleCard(props: Props) {
  const {
    className,
    vehicle,
    href = `/vehicles/stocked/${vehicle.code}`,
    onAddToWishlist,
    isOnWishlist,
    showAvailability = true,
    mediaFit = 'crop',
    wishlistVariant = 'heart-icon',
    ...otherProps
  } = props;

  const showroom = useShowroom();

  const [eagerIsOnWishList, setEagerIsOnWishList] = useState(isOnWishlist);
  const _isOnWishlist = isOnWishlist || eagerIsOnWishList;

  const media: Array<Media> = vehicle.media || [];
  const featuredImage = media.find((media) => media.placement === 'featured');

  const pricingConfig: PricingConfigV2 = vehicle.pricing_config;

  const dutiableValue = pricingConfig.lineItems.dutiable.reduce((acc, item) => acc + Number(item.value), 0);

  const discounts = [
    ...pricingConfig.lineItems.dutiable,
    ...pricingConfig.lineItems.onRoad,
    ...pricingConfig.lineItems.additional,
  ].filter((item) => item.code === 'discount');

  const totalDiscount = Math.abs(
    discounts.reduce((sum: number, item) => sum + (item as PricingLineItem<any>).value, 0),
  );

  const incentives = useGovtIncentives({
    fuelType: vehicle.fuel_type,
    dutiableValue,
    condition: vehicle.condition,
  });

  const availability = (() => {
    if (!showAvailability || !vehicle.available_from || typeof vehicle.estimated_delivery_delay !== 'number') {
      return null;
    }

    const daysToGo = getVehicleAvailabilityInDays(vehicle.available_from, vehicle.estimated_delivery_delay);

    const timeFrame = describeVehicleAvailability(vehicle.available_from, vehicle.estimated_delivery_delay);

    return {
      element: `Available ${timeFrame}`,
      isEmphasised: daysToGo <= AVAILABLE_NOW_THRESHOLD_DAYS,
    };
  })();

  const imageRef = useRef(null);
  const height = useAspectRatio(imageRef, [16, 9]);

  const novatedLeasingLink: string = ['actewagl', 'astrazeneca'].includes(showroom?.code)
    ? '/novated-leasing'
    : '/novated-leasing/polestar';

  const novatedLease = tempPolestarNovatedLease(showroom.code, vehicle);
  const govtRebatesApply = !!incentives.length;

  const priceBeforeOnRoadCosts = sumBy(vehicle.pricing_config.lineItems.dutiable, 'value');

  return (
    <Card
      isFlat
      href={href}
      title={`View more about this car (${vehicle.name})`}
      className={classnames(styles.vehicleCard, className)}
      tabs={
        <>
          {availability && (
            <Card.Tab size="large" color={availability.isEmphasised ? 'primary' : 'gray'}>
              {pricingConfig.isVariable ? 'Enquire for Availability' : availability.element}
            </Card.Tab>
          )}
          {wishlistVariant === 'tab' && onAddToWishlist && (
            <Card.Tab
              onClick={async () => {
                setEagerIsOnWishList(!_isOnWishlist);
                await onAddToWishlist(!_isOnWishlist);
              }}
              variant={_isOnWishlist ? 'outline' : 'filled-not-active'}
              expandOnHover={!_isOnWishlist}
              className={styles.wishList}
            >
              {_isOnWishlist ? (
                <>
                  <AiFillHeart className="inlineIcon" />
                  Saved!
                </>
              ) : (
                <>
                  <RiHeartAddLine className="inlineIcon" />
                  Save for later
                </>
              )}
            </Card.Tab>
          )}
        </>
      }
      {...otherProps}
    >
      <Card.Image
        isFullWidth
        className={styles.image}
        overlayContent={vehicle.is_sold && 'Sold recently'}
        image={
          <img
            ref={imageRef}
            src={getImageCdnUrl(featuredImage?.src, {
              w: 764,
              ar: '16:9',
              fit: mediaFit,
            })}
            height={height}
            alt={vehicle.name}
          />
        }
      />
      <div className={styles.firstLine}>
        <h3 className={styles.name}>{vehicle.name}</h3>
        {wishlistVariant === 'heart-icon' && (
          <WishlistHeartIcon
            onAddToWishlist={onAddToWishlist}
            className={styles.wishlist}
            isOnWishlist={_isOnWishlist}
            setEagerIsOnWishList={setEagerIsOnWishList}
          />
        )}
      </div>
      <div className={styles.description}>
        {vehicle.production_year} {vehicle.make} {vehicle.model} {vehicle.badge}
      </div>
      <div className={styles.tags}>
        {totalDiscount ? <Tag color="primary">{d(totalDiscount)} off</Tag> : <></>}
        {vehicle.range && <Tag>{n(vehicle.range)} km range</Tag>}
        <Tag>
          {getConditionName(vehicle.condition, {
            allowUnofficialNames: !pricingConfig.isVariable,
            odometer: vehicle.odometer,
          })}
        </Tag>
        {vehicle.condition !== 'new' && !pricingConfig.isVariable && (
          <Tag title="Odometer reading">{n(vehicle.odometer)} km</Tag>
        )}
      </div>

      <div className={styles.pricing}>{d(priceBeforeOnRoadCosts)}</div>
      <span
        className={classnames(
          (novatedLease || govtRebatesApply) && styles.statusFlag,
          vehicle.is_reserved && styles.gray,
        )}
      >
        {(() => {
          if (novatedLease) {
            return (
              <>
                <AiOutlineInfoCircle className="inlineIcon" />
                Novated lease only -{' '}
                <Button isText className="hug" href={novatedLeasingLink}>
                  learn more
                </Button>
              </>
            );
          }

          if (govtRebatesApply) {
            return (
              <>
                <AiOutlineInfoCircle className="inlineIcon" />
                Govt. incentives may apply
              </>
            );
          }

          return null;
        })()}
      </span>
    </Card>
  );
}
