import classnames from 'classnames';
import styles from './index.module.scss';

type PropTypes = {
  percentage: number;
  colour: string;
  size?: number;
  fontSize?: number | string;
  fontWeight?: number | string;
  className?: string;
};

export default function CircularProgress({
  percentage,
  colour,
  size = 54,
  fontSize = 13,
  fontWeight = 700,
  className,
}: PropTypes) {
  return (
    <svg width={size} height={size} className={classnames(className && className)}>
      <g transform={`rotate(-90 ${`${size / 2} 100`})`}>
        <Circle colour={'lightgrey'} size={size / 2} />
        <Circle colour={colour} percentage={percentage} size={size / 2} />
      </g>
      <text x="50%" y="50%" dominantBaseline="central" textAnchor="middle" fontSize={fontSize} fontWeight={fontWeight}>
        {percentage.toFixed(0)}%
      </text>
    </svg>
  );
}

type CircleTypes = Partial<Pick<PropTypes, 'size' | 'colour' | 'percentage'>>;

const Circle = ({ colour, percentage, size }: CircleTypes) => {
  const r = size - 5;
  const perimeter = 2 * Math.PI * r;
  const strokePct = perimeter - (percentage / 100) * perimeter;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== perimeter ? colour : ''}
      strokeWidth={'0.2rem'}
      strokeDasharray={perimeter}
      strokeDashoffset={percentage ? strokePct : 0}
      className={styles.circle}
    />
  );
};
