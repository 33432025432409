import { useState } from 'react';
import { HiHeart, HiOutlineHeart } from 'react-icons/hi';
import styles from './index.module.scss';

type Props = {
  onAddToWishlist: Function;
  isOnWishlist: boolean;
};

export const Wishlist = (props: Props) => {
  const { onAddToWishlist, isOnWishlist } = props;

  const [eagerIsOnWishList, setEagerIsOnWishList] = useState(isOnWishlist);
  const _isOnWishlist = isOnWishlist || eagerIsOnWishList;

  return (
    <button
      className={styles.wishlist}
      onClick={(e) => {
        e.preventDefault();
        setEagerIsOnWishList(!_isOnWishlist);
        onAddToWishlist(!_isOnWishlist);
      }}
    >
      {_isOnWishlist ? <HiHeart /> : <HiOutlineHeart />}
    </button>
  );
};
