import { last } from 'lodash';
import { useMemo } from 'react';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { getLoanRepayment } from '../../shared/getLoanRepayment';
import { Fee, RepaymentFrequency } from '../../types';

export type FixedRateFinanceArgs = {
  downpayment: number;
  vehiclePrice: number;
  creditTerm: number;
  interestRate: number;
  comparisonRate: number;
  balloon: number;
  feeSchedule: Fee[];
  repaymentFrequency: RepaymentFrequency;
};

export type FixedRateFinance = {
  repayment: number;
  repaymentFrequency: RepaymentFrequency;
  loanAmount: number;
};

export const getFixedRateFinance = ({
  downpayment,
  vehiclePrice,
  interestRate,
  comparisonRate,
  creditTerm,
  balloon,
  feeSchedule,
  repaymentFrequency = 'monthly',
}: FixedRateFinanceArgs): FixedRateFinance => {
  return getLoanRepayment({
    creditAmount: vehiclePrice - (downpayment || 0),
    creditTerm,
    balloonAmount: balloon ? vehiclePrice * (balloon / 100) : 0,
    repaymentFrequency,
    rate: comparisonRate || interestRate,
    feeSchedule,
  });
};

export type FixedRateFinanceShowroomSettings = {
  finance_fee_schedule: Fee[];
  finance_min_deposit: number;
  vehicle_order_min_deposit: number;
  finance_term_options: number[];
  finance_locked_interest_rate: number;
  finance_locked_comparison_rate: number;
  feature_finance_fixed_rate_enabled: boolean;
  finance_repayment_frequency: RepaymentFrequency;
};

export const getFixedRateDefaults = (
  showroomSettings: FixedRateFinanceShowroomSettings,
): Omit<FixedRateFinanceArgs, 'vehiclePrice'> => {
  const termOptions = showroomSettings.finance_term_options || [];

  return useMemo(
    () => ({
      downpayment: showroomSettings.finance_min_deposit ?? showroomSettings.vehicle_order_min_deposit ?? 0,
      creditTerm: last(termOptions),
      interestRate: showroomSettings.finance_locked_interest_rate,
      comparisonRate: showroomSettings.finance_locked_comparison_rate,
      balloon: 0,
      feeSchedule: showroomSettings.finance_fee_schedule,
      repaymentFrequency: showroomSettings.finance_repayment_frequency || 'monthly',
    }),
    [showroomSettings],
  );
};

export const useFixedRateDefaults = (): Omit<FixedRateFinanceArgs, 'vehiclePrice'> => {
  const showroomSettings = useShowroomSettings([
    'finance_fee_schedule',
    'finance_min_deposit',
    'vehicle_order_min_deposit',
    'finance_term_options',
    'finance_locked_interest_rate',
    'finance_locked_comparison_rate',
    'feature_finance_fixed_rate_enabled',
    'finance_repayment_frequency',
  ]);

  return useMemo(() => getFixedRateDefaults(showroomSettings), [showroomSettings]);
};
