import { Fee } from '../types';

export const getFeeTotalByFrequency = (frequency: Fee['frequency'], feeSchedule: Fee[]) => {
  return (feeSchedule || []).reduce((acc, fee) => {
    if (fee.frequency === frequency) {
      return acc + fee.amount;
    }
    return acc;
  }, 0);
};
