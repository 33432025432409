import pmt from 'formula-pmt';
import { Fee, RepaymentFrequency } from '../types';
import { getFeeTotalByFrequency } from './getFeeTotalByFrequency';

type Args = {
  creditAmount: number;
  creditTerm: number;
  balloonAmount?: number;

  repaymentFrequency: RepaymentFrequency;

  /**
   * @property rate
   *
   * This should be the comparison rate, if the local financial service uses one. Otherwise, the interest rate.
   */
  rate: number;

  feeSchedule?: Fee[];
};

export const getLoanRepayment = (args: Args) => {
  const term = Number(args.creditTerm);

  const loanAmount = args.creditAmount + getFeeTotalByFrequency('one-off', args.feeSchedule);

  let repaymentsPerYear = 12;
  if (args.repaymentFrequency === 'weekly') {
    repaymentsPerYear = 52;
  }

  let totalRepayments = (term / 12) * repaymentsPerYear;
  // If the loan has a final balloon payment, subtract one repayment from the total
  if (args.balloonAmount > 0) {
    totalRepayments -= 1;
  }

  let repayment = Math.round(
    pmt(args.rate / 100 / repaymentsPerYear, totalRepayments, -loanAmount, args.balloonAmount || 0),
  );

  repayment = repayment + getFeeTotalByFrequency(args.repaymentFrequency, args.feeSchedule);

  return { repayment, repaymentFrequency: args.repaymentFrequency, loanAmount };
};
