import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import styles from './index.module.scss';

type PropTypes = {
  onAddToWishlist: Function;
  className?: string;
  isOnWishlist: boolean;
  setEagerIsOnWishList: (boolean) => void;
};

export const WishlistHeartIcon = (props: PropTypes) => {
  const { onAddToWishlist, className, isOnWishlist, setEagerIsOnWishList } = props;

  return (
    (onAddToWishlist && (
      <a
        className={className}
        onClick={async (e) => {
          e.preventDefault();
          setEagerIsOnWishList(!isOnWishlist);
          await onAddToWishlist(!isOnWishlist);
        }}
      >
        {isOnWishlist ? (
          <AiFillHeart title="Saved!" className={styles.heart} />
        ) : (
          <AiOutlineHeart title="Save for later" className={styles.heart} />
        )}
      </a>
    )) || <></>
  );
};
