export const PAYMENT_PLAN_MONTHLY_ACCOUNT_FEE = 7_00;
export const PAYMENT_PLAN_ESTABLISHMENT_FEE = 272_00;
export const PAYMENT_PLAN_MIN_CREDIT_AMOUNT = 5_000_00;
export const PAYMENT_PLAN_MAX_CREDIT_AMOUNT = 50_000_00;
export const PAYMENT_PLAN_MIN_CREDIT_TERM = 12;
export const PAYMENT_PLAN_MAX_CREDIT_TERM = 48;

export const AU_GST_RATE = 0.1;
export const NSW_REGISTRATION_COST = 1_069_00;

export const MAIN_PHONE_NUMBER = '02 8311 4239';
export const MAIN_EMAIL = 'hello@vyro.com.au';

export const LAUNCH_OFFER_EXPIRY_DATE = 'June 30 2022';
export const RIDESHARE_OFFER_EXPIRY_DATE = 'May 31 2022';

export const DNPL_ORDER_EXPIRY = new Date('2022-07-15');
export const DNPL_DELIVERY_EXPIRY = new Date('2022-07-31');

export const DRIVA_CONSUMER_MIN_CREDIT_AMOUNT = 5_000_00;
export const DRIVA_CONSUMER_MAX_CREDIT_AMOUNT = 250_000_00;
export const DRIVA_CONSUMER_MIN_CREDIT_TERM = 12;
export const DRIVA_CONSUMER_MAX_CREDIT_TERM = 84;
export const DRIVA_RECOMMENDED_CREDIT_TERMS = [36, 48, 60, 84];

export const DRIVA_COMMERCIAL_MIN_CREDIT_AMOUNT = 2_000_00;
export const DRIVA_COMMERCIAL_MAX_CREDIT_AMOUNT = 450_000_00;
export const DRIVA_COMMERCIAL_MIN_CREDIT_TERM = 12;
export const DRIVA_COMMERCIAL_MAX_CREDIT_TERM = 84;

export const AVAILABLE_NOW_THRESHOLD_DAYS = 14;

export const ACT_SUSTAINABLE_HOUSEHOLD_SCHEME_CREDIT_TERMS = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120];
