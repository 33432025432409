import { MutableRefObject, Ref, useEffect, useRef, useState } from 'react';
import { useDebounce } from './useDebounce';

/**
 * Returns the height that an element needs to be in order to maintain the aspect ratio
 *
 * @param element the element, or a parent element that shares the width of the child
 * @param aspect the aspect ratio as a two element array. e.g. [16,9]
 * @returns height (Number)
 */
export const useAspectRatio = (element: MutableRefObject<HTMLElement>, aspect: [number, number]) => {
  const [width, setWidth] = useState(0);
  const [debouncedWidth] = useDebounce(width, 300);
  const aspectRatio = aspect[0] / aspect[1];
  const height = debouncedWidth / aspectRatio;

  useEffect(() => {
    if (typeof window === undefined || !element.current) {
      return;
    }

    const handler = () => {
      setWidth(element.current?.getBoundingClientRect().width);
    };

    handler();

    window.addEventListener('resize', handler, { passive: true });

    return () => window.removeEventListener('resize', handler);
  }, [element]);

  return height;
};
