import styles from './index.module.scss';
import classnames from 'classnames';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  color?: 'gray' | 'primary' | 'showroomSpecific' | 'black';
  size?: 'normal' | 'large';
  /**
   * isLive
   *
   * Shows an indicator on the tag to bring attention to it
   */
  isLive?: boolean;
  bgPrimary?: boolean;
  isLessCurvy?: Boolean;
} & Record<string, any>;

export default function Tag(props: Props) {
  const {
    children,
    className,
    color = 'gray',
    size = 'normal',
    isLive = false,
    isLessCurvy,
    bgPrimary,
    ...otherProps
  } = props;

  return (
    <span
      className={classnames(
        styles.tag,
        styles[color],
        styles[size],
        bgPrimary && styles['bgPrimary'],
        isLessCurvy && styles['isLessCurvy'],
        isLive && styles.isLive,
        className,
      )}
      {...otherProps}
    >
      {children}
      {isLive && <span className={styles.liveIndicator} aria-hidden={true} />}
    </span>
  );
}
