import StandardVehicleCard from './variants/Standard';
import { Props } from './types';
import PolestarVehicleCard from './variants/Polestar';
import LotusVehicleCard from './variants/Lotus';
import CbaVehicleCard from './variants/Cba';

export type VehicleCardProps = Props;

export default function VehicleCard(props: Props) {
  let Component = null;
  switch (props.variant) {
    case 'polestar':
      Component = PolestarVehicleCard;
      break;
    case 'lotus':
      Component = LotusVehicleCard;
      break;
    case 'cba':
      Component = CbaVehicleCard;
      break;
    default:
      Component = StandardVehicleCard;
  }

  return <Component {...props} />;
}
