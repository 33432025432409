import classnames from 'classnames';
import { AVAILABLE_NOW_THRESHOLD_DAYS } from '../../../../../constants';
import { describeVehicleAvailability, getVehicleAvailabilityInDays } from '../../../../../services/vehicleAvailability';
import { Props as VehicleCardProps } from '../../../types';
import styles from './index.module.scss';
import dayjs from 'dayjs';

type Props = {
  vehicle: VehicleCardProps['vehicle'];
};

export const Availability = (props: Props) => {
  const { vehicle } = props;

  if (!vehicle.available_from || typeof vehicle.estimated_delivery_delay !== 'number') {
    return null;
  }

  const { text, colour } = (() => {
    if (vehicle.is_sold) {
      return { text: 'Sold', colour: 'red' };
    }

    if (vehicle.is_reserved_until && dayjs(vehicle.is_reserved_until).isAfter(dayjs())) {
      return { text: 'Being purchased', colour: 'amber' };
    }

    const daysToGo = getVehicleAvailabilityInDays(vehicle.available_from, vehicle.estimated_delivery_delay);
    const timeFrame = describeVehicleAvailability(vehicle.available_from, vehicle.estimated_delivery_delay);
    const isAvailableNow = daysToGo <= AVAILABLE_NOW_THRESHOLD_DAYS;

    return { text: `Available ${timeFrame}`, colour: isAvailableNow ? 'green' : 'amber' };
  })();

  return (
    <div className={styles.availability}>
      <div className={classnames(styles.indicator, styles[colour])} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};
